<template>
  <article class="career gray-p--bg">
    <LazyCareerHat />
    <LazyCareerSteps />
    <LazyCareerFamiliarity />
    <LazyCareerOffer
      v-if="blockOffer"
      :block-offer="blockOffer"
    />
    <LazyCareerReviews :reviews="reviews" />
    <LazyCareerJoin
      v-if="blockJoin"
      :socials="socials"
      :title="blockJoin.title"
      :text="blockJoin.content"
    />
    <LazyCareerPartners :providers="providers" />
    <LazyCareerPhoto :photos="photos" />
    <LazyCareerForm />

    <LazySearchAddressPageSearchAddressFAQ
      title="Часто задаваемые вопросы"
      :faq-block="faqBlock"
    />
    <LazyCareerLast />
    <LazyCareerFooter :socials="socials" />
  </article>
</template>

<script setup lang="ts">
import { timeArray, timeEnter } from '~/api/openapi/common'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { textAggReplace } from '~/helpers'
import getTextAsync from '~/composible/getPageText'

definePageMeta({
  layout: 'empty',
})

const ctx = useNuxtApp()
const tariffsStore = useTariffs()
const getAddressText = computed(() => tariffsStore.getAddressText)
const mainStore = useMainStore()
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const client = ctx._apolloClients.default

const fetchAllProviders = async () => {
  const timeStart = Date.now()
  const res = await client.query({
    query: gql`
      query getAllProviders($getProvidersInput2: ProvidersInput) {
        core {
          getProviders(input: $getProvidersInput2) {
            data {
              id
              slug
              name
              logoT {
                url(options: { width: 200, halign: "left" })
              unoptimized
              }
            }
          }
        }
      }
    `,
    variables: {
      getProvidersInput2: {
        withTarifflessAddresses: true,
        pageSize: 12,
      },
    },
    context: {
      headers: getCommonHeaders.value,
    },
    fetchPolicy: 'no-cache',
  })
  const timeEnd = Date.now()
  const obj = {
    timeStart: timeStart - timeEnter,
    timeDif: timeEnd - timeStart,
    url: 'getAllProviders',
  }
  timeArray.push(obj)

  return res.data.core.getProviders.data
}
const textAsync = computed(() => data.value?.textAsync)
const providers = computed(() => data.value?.providers)
const faqBlock = computed(() => textAsync.value?.blocks?.faqs)
const socials = computed(() => textAsync.value?.extra?.socials)
const blockJoin = computed(
  () =>
    textAsync.value?.blocks?.articles?.find((el: any) => el.target === 'join'),
)
const blockOffer = computed(
  () =>
    textAsync.value?.blocks?.articles?.find((el: any) => el.target === 'job'),
)
const photos = computed(() => textAsync.value?.extra?.photos)
const reviews = computed(() => textAsync.value?.extra?.reviews)

const { data } = await useAsyncData('careerPage', async () => {
  let textAsync
  let providers

  const textAsyncFetch = async () => {
    try {
      textAsync = await getTextAsync({ url: '/career/' })
    }
    catch (e: any) {
    }
    finally {
      //
    }
  }
  const fecthProviders = async () => {
    providers = await fetchAllProviders()
  }

  await Promise.all([fecthProviders(), textAsyncFetch()])

  return { providers, textAsync }
}, {
  transform(input) {
    return {
      ...input,
      fetchedAt: new Date(),
    }
  },
  getCachedData(key) {
    const data = ctx.payload.data[key] || ctx.static.data[key]
    if (!data) {
      return
    }

    const expirationDate = new Date(data.fetchedAt)
    expirationDate.setTime(expirationDate.getTime() + 7 * 60 * 60 * 1000)
    const isExpired = expirationDate.getTime() < Date.now()

    if (isExpired) {
      return
    }
    return data
  },
})

useHead(() => ({
  title: () =>
    textAsync.value?.title
      ? textAggReplace(
        textAsync.value.title,
        getAddressText.value,
      )
      : '',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: () =>
        textAsync.value?.description
          ? textAggReplace(
            textAsync.value.description,
            getAddressText.value,
          )
          : '',
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () =>
        `Работай специалистом контакт-центра из любой точки России!`,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: `https://dominternet.ru/media/uploads/blog/careerHat.png`,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: () =>
        textAggReplace(textAsync.value?.description, getAddressText.value),
    },
    {
      name: textAsync.value?.index ? '' : 'robots',
      content: textAsync.value?.index ? '' : 'noindex,nofollow',
    },
  ],
}))
</script>

<style scoped lang="scss"></style>
